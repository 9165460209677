.App {
  text-align: center;
  background-image: linear-gradient(rgb(240, 240, 240), rgb(248, 242, 242),rgb(248, 220, 224), rgb(255, 172, 186));
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.eyeContainer{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
}

.eyeM{
  width: 100%;
}

.eyeD{
  width: 75%;
  display: none;
}

@media (min-width: 720px){
  .eyeM{
    display: none;
  }
  .eyeD{
    display: flex;
  }
}
@keyframes popInOut {
  0%{opacity: 0}
  40%{opacity: 1}
  70%{opacity: 1}
  0%{opacity: 0}
}

@keyframes popIn {
  0%{opacity: 0}
  40%{opacity: 1}
  100%{opacity: 1}
}

@keyframes bounce {
  0%{transform: translateY(-7px)}
  50%{transform: translateY(0)}
  100%{transform: translateY(-7px)}
}

@keyframes popInDelayed {
  0%{opacity: 0}
  30%{opacity: 0}
  75%{opacity: 1}
  100%{opacity: 1}
}

.home{
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 2;
  /* padding-bottom: 12rem; */
}

.homeTopD, .homeTopM{
  opacity: 0;
  animation: popInOut ease-in-out 3s, bounce ease-in-out 2s;
  animation-iteration-count: 2, infinite;
}

.homeTopDF, .homeTopMF{
  animation: popIn ease-in-out 3s, bounce ease-in-out 2s;
  animation-iteration-count: 1, infinite;
}

.homeTopDStable, .homeTopMStable{
  animation: bounce ease-in-out 2s;
  animation-iteration-count: infinite;
}


.homeTopD, .homeTopDF, .homeTopDStable{
  display: none;
  height: 30vh;
  align-items: flex-end;

  /* justify-content: center; */
}

.homeTopM, .homeTopMF, .homeTopMStable{
  height: 45vh;
  display: flex;
  align-items: flex-end;
}

.homeBottom, .homeBottomM {
  margin-top: 5vh;
  height: 30vh;
  display:flex;
}

.homeBottomM{
  margin-bottom: 10vh;
}

.btnContainer, .btnContainerStable {
  margin: 16px 16px;
  width: 30vw;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.btnContainer{
  animation: popInDelayed ease-in-out 4s;
  animation-iteration-count: 1;
}

.no, .yes {
  font-family: 'Barlow', sans-serif;
  font-size: 1.5rem;
  background-color: transparent;
  border: solid black;
  border-radius: 2px;
  border-width: 1px;
  width: 7.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  transition: box-shadow 0.1s ease-in;
  /* opacity: 0; */
  margin-top: 4px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no:active, .yes:active {
  background-color: #E5E7E2;
  border-width: 2px;
  border-radius: 3px;
}

.goodreading{
  width: 75vw;
}

.goodreadingM{
  width: 75vw;
  margin-bottom: 7vw;
}

.smile{
  width: 24vw;
}

.smileM{
  width: 24vw;
  margin-bottom: 7vw;
}

.areyou{
  width: 75vw;
}

.eyeContainer{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
}

.eyeM{
  width: 100%;
}

.eyeD{
  width: 75%;
  display: none;
}

@media (min-width: 720px){
  .homeTopD, .homeTopDF, .homeTopDStable{
    display: flex;
  }
  .homeTopM, .homeTopMF, .homeTopMStable{
    display:none
  }
  .home{
    padding-bottom: 0;
    /* padding-top: 4rem; */
  }
  .btnContainer, .btnContainerStable{
    width: 10rem;
  }
  .no:hover, .yes:hover {
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.05s ease-out;
  }
  .no:active, .yes:active {
    background-color: #E5E7E2;
    margin-top: 6px;
    margin-left: 4px;
    box-shadow: none;
  }
  .eyeM{
    display: none;
  }
  .eyeD{
    display: flex;
  }

}
html {
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
}

.purchasePageD{
  height: 100vh;
  width: 100vw;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  font-family: 'Space Mono', 'monospace';
  z-index: 3;
}


.homeBtnM, .aboutBtnM, .purchaseBtnM {
  font-family: 'Space Mono', 'monospace';
  font-weight: 300;
  color: black;
  background-color: transparent;
  border: solid 0.4rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  font-size: 2rem;
  padding-left: 1rem;
}

.homeBtnD, .aboutBtnD, .purchaseBtnD {
  font-family: 'Space Mono', 'monospace';
  font-weight: 700;
  color: black;
  background-color: transparent;
  border: solid 0.2rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  font-size: 1.2rem;
  padding-left: 0.8rem;
}

.homeBtnD{
  margin-top: 4rem;
}

.selectedNav{

  border-radius: 1px;

  border-left-color: #9C0002;
}

.purchaseSectionD{
  height: 100%;
  width: 82vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #71968A;
  /* color: white; */
  z-index: 3;
}


.purchaseSDTop{
  width: 70%;
  height: 55vh;
  display: flex;
}

.purchaseSDTopLeft{
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: left;
  align-items: top;
}

.coverD{
  height: 100%;
}

.purchaseSDTopRight{
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.authorDesc{
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: center;
}

.titleD{
  font-weight: 800;
  font-size: 4vh;
  margin: 3vh 0 0 0;
  color: #FFECEA;
}

.authorD{
  font-size: 2.5vh;
  color: #E6D9D4;
}

.purchaseLinksD{
  width: 100%;
  height: 65%;
  border: solid;
  border-radius: 1px;
  border-color: transparent;
  border-top-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.purchaseOnD{
  margin: 4vh 0 0 0;
  font-size: 3vh;
  font-weight: 600;
  text-align: center;
  color: #FFECEA;
}

.purchaseLinkD{ 
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Space Mono', monospace;
  color: black;
  font-size: 2.5vh;
  font-weight: 600;
  width: 30vh;
  height: 4vh;
  margin-top: 1rem;
  margin-bottom: 1%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border: solid;
  border-radius: 0;
  border-width: 1px;
  border-color: black;
  background-color: #E6D9D4;
}

.purchaseSDBottom{
  width: 70%;
  height: 28vh;
  margin-top: 2rem;
  border: solid;
  border-radius: 1px;
  border-color: transparent;
  border-top-color: white;
}

.synopsisD{
  font-size: 3vh;
  font-weight: 600;
  color: #FFECEA;
  text-align: left;
}

.synopsisTextD{
  font-size: 2.3vh;
  color: #E6D9D4;
  text-align: left;
}

.purchasePageM{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #71968A;
  font-family: 'Space mono', 'monospace';
  /* color: white; */
  z-index: 3;
  position: absolute;
  top: 0;
}

.coverContainerM{
  height: 50%;
  width: 75%;
  margin-top: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverM{
  width: 45vw;
}

.titleM{
  font-size: 6vw;
  font-weight: 700;
  margin: 2vh 0 0 0;
  color: #FFECEA;
}

.authorM{
  margin: 0.5vh 0 1.5vh 0;
  font-size: 3.8vw;
  color: #E6D9D4;
}

.purchaseLinksM{
  width: 80%;
  border: solid;
  border-radius: 1px;
  border-color: transparent;
  border-top-color: white;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchaseOnM{
  font-weight: 600;
  font-size: 3.8vw;
  margin: 2vh 0 1vh 0;
  color: #FFECEA;
}

.purchaseLinkM{ 
  text-align: center;
  color: black;
  font-size: 3.4vw;
  font-weight: 600;
  width: 45vw;
  height: 1.8rem;
  text-align: center;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border: solid;
  border-radius: 0;
  border-width: 1px;
  border-color: black;
  background-color: #E6D9D4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.synopsisM{
  width: 80%;
  margin: 0;
}

.synopsisTitleM{
  margin: 1vh 0 0 0;
  width: 100%;
  margin-top: 1.5vh;
  height: 4vw;
  display: flex;
  justify-content: space-between;
}

.synopsisDescM{
  font-size: 3.8vw;
  font-weight: 600;
  margin: 0;
  color: #FFECEA;
}

/* .chevronContainer{
  height: 100%;
}

.chevrondown, .chevronup{
  height: 85%;
} */

.synopsisTextM{
  font-size: 3.6;
  color: #E6D9D4;
  text-align: justify;
}

.hidden {
  display: none;
}

@media (min-width: 720px){
  .purchasePageD{
    display: flex;
  }
  .purchasePageM{
    display: none;
  }
}